// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: black; /* Set the background color to black */
  margin: 0; /* Remove default body margins */
  overflow: hidden; /* Prevent scrollbars */
  fontFamily: 'Roboto', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB,EAAE,sCAAsC;EAC/D,SAAS,EAAE,gCAAgC;EAC3C,gBAAgB,EAAE,uBAAuB;EACzC,gCAAgC;AAClC","sourcesContent":["body {\n  background-color: black; /* Set the background color to black */\n  margin: 0; /* Remove default body margins */\n  overflow: hidden; /* Prevent scrollbars */\n  fontFamily: 'Roboto', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
